.slick-dots-nourish {
  position: absolute;
  bottom: -25px;

  display: block;

  width: 100%;
  padding: 0;
  margin: 0;

  list-style: none;

  text-align: center;
}
.slick-dots-nourish li {
  position: relative;

  display: inline-block;

  width: 20px;
  height: 20px;
  margin: 0 0px;
  padding: 0;

  cursor: pointer;
}
.slick-dots-nourish li button {
  font-size: 0;
  line-height: 0;

  display: block;

  width: 20px;
  height: 20px;
  padding: 5px;

  cursor: pointer;

  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.slick-dots-nourish li button:hover,
.slick-dots-nourish li button:focus {
  outline: none;
}
.slick-dots-nourish li button:hover:before,
.slick-dots-nourish li button:focus:before {
  opacity: 1;
}
.slick-dots-nourish li button:before {
  font-family: "slick";
  font-size: 10px;
  line-height: 20px;

  position: absolute;
  top: 0;
  left: 0;

  width: 20px;
  height: 20px;

  content: "•";
  text-align: center;

  opacity: 0.25;
  color: #16b59c;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots-nourish li.slick-active button:before {
  opacity: 1;
  color: #16b59c;
}
