.animated-btn {
  position: absolute !important;
}

.animated-btn input {
  position: absolute;
  left: -100vw;
}
.animated-btn input:checked + label {
  color: #e2264d;
  will-change: font-size;
  animation: heart 1s cubic-bezier(0.17, 0.89, 0.32, 1.49);
}
.animated-btn input:checked + label:before,
.animated-btn input:checked + label:after {
  animation: inherit;
  animation-timing-function: ease-out;
}
.animated-btn input:checked + label:before {
  will-change: transform, border-width, border-color;
  animation-name: bubble;
}
.animated-btn input:checked + label:after {
  will-change: opacity, box-shadow;
  animation-name: particles;
}

.animated-btn label {
  align-self: center;
  position: relative;
  color: #aab8c2;
  font-size: 2em;
  user-select: none;
  cursor: pointer;
}
.animated-btn label:before,
.animated-btn label:after {
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  content: "";
}
.animated-btn label:before {
  box-sizing: border-box;
  margin: -2.25rem;
  border: solid 2.25rem #e2264d;
  width: 4.5rem;
  height: 4.5rem;
  transform: scale(0);
}
.animated-btn label:after {
  margin: -0.1875rem;
  width: 0.375rem;
  height: 0.375rem;
  box-shadow: 0.32476rem -3rem 0 -0.20625rem #ff8080,
    -0.32476rem -2.625rem 0 -0.20625rem #ffed80,
    2.54798rem -1.61656rem 0 -0.20625rem #ffed80,
    1.84982rem -1.89057rem 0 -0.20625rem #a4ff80,
    2.85252rem 0.98418rem 0 -0.20625rem #a4ff80,
    2.63145rem 0.2675rem 0 -0.20625rem #80ffc8,
    1.00905rem 2.84381rem 0 -0.20625rem #80ffc8,
    1.43154rem 2.22414rem 0 -0.20625rem #80c8ff,
    -1.59425rem 2.562rem 0 -0.20625rem #80c8ff,
    -0.84635rem 2.50595rem 0 -0.20625rem #a480ff,
    -2.99705rem 0.35095rem 0 -0.20625rem #a480ff,
    -2.48692rem 0.90073rem 0 -0.20625rem #ff80ed,
    -2.14301rem -2.12438rem 0 -0.20625rem #ff80ed,
    -2.25479rem -1.38275rem 0 -0.20625rem #ff8080;
}

@keyframes heart {
  0%,
  17.5% {
    font-size: 0;
  }
}
@keyframes bubble {
  15% {
    transform: scale(1);
    border-color: #cc8ef5;
    border-width: 2.25rem;
  }
  30%,
  100% {
    transform: scale(1);
    border-color: #cc8ef5;
    border-width: 0;
  }
}
@keyframes particles {
  0%,
  20% {
    opacity: 0;
  }
  25% {
    opacity: 1;
    box-shadow: 0.32476rem -2.4375rem 0 0rem #ff8080,
      -0.32476rem -2.0625rem 0 0rem #ffed80,
      2.1082rem -1.26585rem 0 0rem #ffed80,
      1.41004rem -1.53985rem 0 0rem #a4ff80,
      2.30412rem 0.85901rem 0 0rem #a4ff80, 2.08305rem 0.14233rem 0 0rem #80ffc8,
      0.76499rem 2.33702rem 0 0rem #80ffc8, 1.18748rem 1.71734rem 0 0rem #80c8ff,
      -1.35019rem 2.0552rem 0 0rem #80c8ff,
      -0.60229rem 1.99916rem 0 0rem #a480ff,
      -2.44865rem 0.22578rem 0 0rem #a480ff,
      -1.93852rem 0.77557rem 0 0rem #ff80ed,
      -1.70323rem -1.77366rem 0 0rem #ff80ed,
      -1.81501rem -1.03204rem 0 0rem #ff8080;
  }
}
