@font-face {
  font-family: "icomoon";
  src: url("fonts/icomoon/icomoon.eot?xvwefh");
  src: url("fonts/icomoon/icomoon.eot?xvwefh#iefix") format("embedded-opentype"),
    url("fonts/icomoon/icomoon.ttf?xvwefh") format("truetype"),
    url("fonts/icomoon/icomoon.woff?xvwefh") format("woff"),
    url("fonts/icomoon/icomoon.svg?xvwefh#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  font-size: large;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-icon-vegetables:before {
  content: "\e900";
  color: #16b59c;
}
.icon-icon-vegetable_protein:before {
  content: "\e901";
  color: #16b59c;
}
.icon-icon-sweeteners:before {
  content: "\e902";
  color: #16b59c;
}
.icon-icon-seasonings:before {
  content: "\e903";
  color: #16b59c;
}
.icon-icon-sauces:before {
  content: "\e904";
  color: #16b59c;
}
.icon-icon-fruits:before {
  content: "\e905";
  color: #16b59c;
}
.icon-icon-fats:before {
  content: "\e906";
  color: #16b59c;
}
.icon-icon-drinks:before {
  content: "\e907";
  color: #16b59c;
}
.icon-icon-carbohydrates:before {
  content: "\e908";
  color: #16b59c;
}
.icon-icon-animal_protein:before {
  content: "\e909";
  color: #16b59c;
}
.icon-tealeaf-white .path1:before {
  content: "\e90a";
  color: rgb(255, 253, 249);
}
.icon-tealeaf-white .path2:before {
  content: "\e90b";
  margin-left: -0.94140625em;
  color: rgb(249, 246, 244);
}
.icon-tealeaf-white .path3:before {
  content: "\e90c";
  margin-left: -0.94140625em;
  color: rgb(249, 246, 244);
}
.icon-tealeaf-white .path4:before {
  content: "\e90d";
  margin-left: -0.94140625em;
  color: rgb(255, 253, 249);
}
.icon-tealeaf-white .path5:before {
  content: "\e90e";
  margin-left: -0.94140625em;
  color: rgb(249, 246, 244);
}
