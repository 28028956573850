*:before,
*:after {
  box-sizing: border-box;
}
.hide-arrow {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  background-image: none;
  border: none; /* Optional: if you want to remove the border */
  padding: 0; /* Optional: if you want to remove any padding */
}
.hide-arrow:focus {
  border: none;
  box-shadow: none; /* Remove any default focus shadow */
  outline: none; /* Ensure no outline on focus */
}
/* Add a custom background */
.hide-arrow {
  position: relative;
}

/* Optional: Add a custom arrow */
.hide-arrow::after {
  content: "▼"; /* Unicode character for a downward arrow */
  position: absolute;
  right: 10px; /* Adjust as needed */
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none; /* Ensure the custom arrow does not block clicks */
}
